* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;

}

html {
  scroll-behavior: smooth;
}

/*MMMMMMMM BARRA DE NAVEGACION SUPERIOR MMMMMMMM*/
/*.menu-list li a {
    color: #060a24;
    line-height: 28px;
}
.menu-list li a {
    padding-left: 2rem;
    color: #fff;
    text-transform: uppercase;
}
a {
    text-decoration: none;
    text-align: center;
    font-weight: 400;
    color: #060a24;
}

.anclas{
    display: flex;
    justify-content: space-between;
}*/

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.hero {
  background-image: linear-gradient(
      to top,
      rgba(59, 66, 196, 0.623) 0%,
      rgba(169, 129, 187, 0.466) 75%,
      #a09690 100%
    ),
    url("../img/fondo_prin.jpg");
  width: 100%;
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
}

.textos-hero h1 {
  font-size: 40px;
  text-align: center;
  color: #2c303b;
  margin-bottom: 50px;
}

.textos-hero p {
  font-size: 25px;
  margin-bottom: 20px;
}

.textos-hero {
  height: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textos-hero a {
  display: inline-block;
  text-decoration: none;
  padding: 0px 15px;
  background: rgb(27, 99, 145);
  border-radius: 8px;
  color: white;
}

.svg-hero {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.textos-hero > img {
  width: 250px;
  padding-top: 0px;
}

.textos-hero span {
  font-weight: bold;
}

.textos-hero > a {
  background: transparent;
}

/*ESTILOS GENERALES*/

.contenedor,
.wave-contenedor {
  width: 75%;
  overflow: hidden;
  margin: auto;
  padding: 0 0 80px;
}

.contenedor {
  padding-top: 0;
}

.titulo {
  font-weight: 300;
  font-size: 25px;
  text-align: center;
  margin-bottom: 30px;
}

.titulo.left {
  text-align: justify;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom:10px;
}

.titulo.left2 {
  text-align: justify;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom:10px;
}

.titulo.right {
  text-align: right;
}

/*SECTION*/

.website {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}

.website img {
  width: 40%;
}

.website .contenedor-textos-main {
  width: 50%;
}



.parrafo {
  text-align: justify;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.parrafo2 {
  text-align: justify;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.cta {
  display: inline-block;
  text-decoration: none;
  background: #f38115;
  padding: 12px 15px;
  border-radius: 8px;
  color: white;
  
}

.btn-imp{
  display: flex;
}

.titulo {
  font-weight: bold;
  padding-bottom: 50px;
  padding-top: 10px;
}

/*INFO*/

.info {
  background: #679ae7;
  color: white;
  display: flex;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMCATALOGOMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.cursos {
  background: #1c0f92;
  width: 50%;
}

.catalogo {
  background: #b3b1be;
  width: 70%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.titl-area {
  text-align: center;
}

.accordion-button {
  font-weight: bold;
}

nav {
  width: 100%;
}

.list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 30px;
}

.list_item {
  list-style-type: none;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.list_item--click {
  cursor: pointer;
}

.list_button {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 70%;
  margin: 0 auto;
}

.list_button h3 {
  padding: 20px 0;
}

.arrow .list_arrow {
  transform: rotate(90deg);
}

.list_arrow {
  margin-left: auto;
  transition: transform 0.3s;
}

.nav_link {
  color: white;
  display: block;
  padding: 10px 0;
  text-decoration: none;
}

.nav_link--inside:hover {
  background: #8a6ecc;
}

.list_show {
  width: 80%;
  margin-left: auto;
  border-left: 2px solid white;
  list-style: none;
  transition: height 0.4s;
  height: 0;
}

.list_inside a {
  color: #e2a266;
}

.nav_link--inside {
  border-radius: 6px;
  padding-left: 20px;
  text-align: left;
}

.list_button--click .list_show {
  display: none;
}

.nav-dip {
  width: 700px;
}

.li-dip {
  padding-top: 20px;
}

.tabla-cursos > thead {
  width: 500px;
}

.table .table-sm .table-striped .table-bordered .table-hover {
  width: 720px;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMCARDSMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/





.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
  text-align: center;
  height: 300px;
  margin-bottom: 30px;
  margin-left: 55px;
  margin-right: 100px;
 
}

.content-media > p {
  color: #b3b1be;
  padding-bottom: 15px;
}


.card p {
  
  font-weight: 300;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 15px;
}

.card > a {
  width: 80px;
  align-self: center;
}


.card1,
.card2 {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.card1 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.card2 {
  margin-bottom: 30px;
}

.video{
  display: flex;
}



.icono {
  font-size: 40px;
  color: rgb(16, 69, 102);
  margin: 20px auto;
}

.conocenos{
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.conocenos > h3{
  text-align: center;
  padding-bottom: 30px;
}

.conocenos > p{
  text-align: justify;
  font-size: 1.2em;
}

.centrado{
  display: block;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 40px;
}

h2.titulo.left2{
  text-align: center;
}

p.parrafo.parrafo-i{
  margin-left: 50px;
}



/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM CATALOGO DE CURSOS MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/
.cur-dip {
  display: flex;
  justify-content: space-around;
  background: #eeeaea;
  width: 100%;

  margin: 0 auto;
}

.contenedor-cursos > h3 {
  color: #2c303b;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.contenedor-cursos {
  margin-bottom: 50px;
}

.accordion {
  width: 800px;
}

.tabla-cursos {
  width: 1000px;
  margin: 0 auto;
}

.pdfs{
  background: #eeeaea;
}

.pdfs > a{
  color: #f38115;
  font-weight: bold;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMM GALERIA MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.galeria {
  background-color: rgb(186, 191, 196);
}

.galeria-cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: hidden;
}



.carousel-inner{
  width: 400px;
  height: 300px;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM VITRINA DE LA NOTICIA MMMMMMMMMMMMMMMMMMMMM*/

.vitrina {
  background: #f9f9f9;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.caja-not {
  display: flex;
  flex-direction: column;
}


.noticia-ddia {
  margin-top: 20px;
}
.img-not {
  max-width: 500px;
  max-height: 500px;
  padding: 35px 30px 40px 50px;
  margin-left: 280px;
  margin-bottom: 0;
}

.title-not {
  padding-left: 50px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.titulo.left {
  padding-bottom: 0;
  font-size: 30px;
}

.body-not {
  padding-left: 50px;
  padding-right: 50px;
}

.fmt-fecha {
  padding-left: 50px;
  padding-top: 0px;
  padding-bottom: 30px;
}

.fmt-fecha > p {
  line-height: 50%;
}

#example-custom-modal-styling-title > img{
  margin-right: 300px;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM INFO 2 MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.last-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
 
}

 .info-ult{
  background: #eeeaea;
}


.last-section img {
  width: 48%;
}

.last-section .contenedor-textos-main {
  width: 90%;
}

.border {
  width: auto;
  border-bottom: 2px solid #683ec9;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM PAGO MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

input[type="button"] {
  cursor: pointer;
}

#form-p {
  display: block;
}

.pago {
  width: 100%;
}

#formGroupEmail{
  margin-bottom: 10px;
}

#formGroupNombre{
  margin-bottom: 10px;
}

.container-genp {
  width: 800px;
  height: 700px;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  background-color: #c6cfdb;
  border-radius: 20px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgb(102, 96, 96);
}

.container-1p,
.container-2p,
.container-3p {
  padding: 20px 20px 0 20px;
}

.container-1p {
  display: flex;
  margin-bottom: 0;
}

.container-2p {
  display: flex;
  flex-direction: column;
}

.container-3p {
  display: flex;
  justify-content: center;
}

.container-4p {
  display: flex;
  margin-top: 40px;
}

.b1 {
  width: 100%;
}

.mont::placeholder {
  color: white;
}

.b2 {
  display: flex;
  justify-content: space-between;
}

.inputp {
  background: transparent;
  border: 0;
  color: rgb(102, 96, 96);
  outline: none;
  border: 1px solid rgb(102, 96, 96);
  padding: 20px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.ref {
  margin-right: 60px;
  width: 200px;
}

.btn-p {
  padding-top: 30px;
}

.btn-cont {
  margin-top: 40px;
  background-color: #5f7bd6;
}

.mont {
  margin-right: 100px;
}

select > option {
  color: rgb(102, 96, 96);
}

.nombrep {
  margin-right: 20px;
  width: 300px;
}

.emailp {
  width: 100%;
}

.nombrep::placeholder,
.emailp::placeholder {
  color: rgb(102, 96, 96);
}

label {
  color: rgb(102, 96, 96);
  display: block;
}

.cursop {
  padding-bottom: 15px;
}

.diplomadop {
  padding: 15px 0 15px 0;
}

.titulop {
  color: rgb(102, 96, 96);
  text-align: center;
  padding-top: 20px;
}

.banco {
  width: 200px;
  padding-bottom: 20px;
}

.ref,
.monto {
  width: 100px;
  margin-left: 20px;
}

.ref::placeholder,
.monto::placeholder {
  color: white;
}

.enviarp {
  width: 80px;
  margin-top: 20px;
  font-weight: bold;
}

input[type="submit"]:hover {
  cursor: pointer;
  color: #679ae7;
  background: white;
}

.banc {
  padding-top: 20px;
  padding-left: 20px;
}

.cerrar {
  text-align: center;
  padding-top: 15px;
}

.boton-cont {
  display: flex;
  justify-content: center;
}

.btn-cont {
  margin-left: auto;
  margin-right: auto;
}

#contacto-id, #inscribete-id{
  display: flex;
}

.texto-contacto, .texto-notificacion{
  margin-left: 150px;
}

.boton-contacto{
  margin-top: 80px;
}

.boton-notificacion{
  margin-top: 80px;
}
/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM FOOTER -CONTACTO MMMMMMMMMMMMMMMMMMMMMMMM*/

footer {
  background: linear-gradient(
    0deg,
    rgba(217, 217, 217, 1) 0%,
    rgba(230, 230, 230, 1) 35%,
    rgba(246, 246, 246, 1) 100%
  );
}

#contacto h1 {
  color: black;
  font-weight: 500;
  text-align: center;
  margin-left: 50px;
}

.resp-cont {
  row-gap: 45px;
}

.reductor {
  width: 60%;
  margin: 0 auto;
}


.btnContacto {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

/*.form{
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }*/

.container-gen {
  display: flex;
  flex-direction: column;
}

.respon {
  display: flex;
  margin: 10px 10px 0 10px;
}

.container-3 {
  display: flex;
  flex-direction: column;
}

.input .nombre {
  width: 100%;
  justify-content: center;
}

.email {
  width: 100%;
}

.nombre {
  margin-right: 20px;
}

.pais {
  width: 20%;
  height: 40px;
  margin-right: 10px;
  margin-left: 15px;
}

.ven {
  text-decoration: underline;
}

.tel1 {
  width: 20%;
  margin-right: 10px;
}

.input {
  background: transparent;
  border: 0;
  color: rgb(102, 96, 96);
  outline: none;
  border: 1px solid rgb(102, 96, 96);
  padding: 20px 10px;
  border-radius: 8px;
}

.cur > label,
.dip > label {
  color: white;
  padding-bottom: 20px;
}

.cur,
.dip {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.cur {
  margin-right: 15px;
}

.input::placeholder {
  color: rgb(102, 96, 96);
  font-family: "raleway", "sans-serif";
}

input[type="text"],
input[type="email"] {
  display: inline-block;
  width: 49%;
  margin-bottom: 30px;
}

.etiqueta{
  font-weight: bold;
  font-size: .8em;
}

/*input[type="number"]{
    display: inline-block;
    width: 49%;
    margin-bottom: 30px;
}*/

.tel {
  width: 35%;
}

.container-3p > select {
  height: 66px;
  width: 25%;
}

.form textarea {
  width: 50%;
  height: 100px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

input[type="submit"] {
  width: 200px;
  text-align: center;
  padding: 14px 0;
  margin-left: auto;
  margin-right: auto;
}

input[type="submit"]:hover {
  cursor: pointer;
  color: #679ae7;
  background: white;
}

select > option {
  color: #1c0f92;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMSOCIALMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.social {
  background-color: #f38115;
  height: 239px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 49px;
  transform: scale(1);
  transition: transform 0.6s;
}

.social a {
  text-decoration: none;
  color: rgb(192, 183, 183);
  font-size: 25px;
}

.social a:hover {
  transform: scale(1.4);
  transition: 0.6s ease-in-out;
  color: rgb(175, 186, 197);
}

.social-flex {
  display: flex;
  justify-content: space-around;
  padding-bottom: 30px;
  color: #2c303b;
}

.sociales{
  display: flex;
  justify-content: space-around;
  margin-left: -300px;
}

.twitter {
  padding-right: 40px;
  color: #2c303b;
}

.bcv{
  padding-right: 40px;
}


.copyright {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 50px;
  color: #d9d7df;
  background: #f38115;
}

.ref-moodle {
  font-size: small;
}

.moodle {
  color: #696464;
}

.block-foto {
  height: 413px;
  object-fit: cover;
}

.style-login {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.contacto {
  width: 70%;
}

.style-login > h3 {
  text-align: center;
  color: white;
  padding-bottom: 30px;
}

.caja-login {
  width: 800px;
  height: 400px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  background: rgb(214, 212, 212);
  border: 1px solid rgb(102, 96, 96);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.login-input {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.tit-crear-n > h2 {
  color: #2c303b;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 30px;
}

.mb-3 {
  display: flex;
  flex-direction: column;
}

.form-control {
  height: 50px;
}

.actz-cur {
  background: #f6f6f6;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.actz-cur h2 {
  color: #2c303b;
  text-align: center;
  padding-bottom: 50px;
}

.btn-inicio {
  margin-right: auto;
  margin-left: auto;
}

.btn-actz {
  display: flex;
  justify-content: center;
}

.contenedorN {
  background: linear-gradient(
    0deg,
    rgba(217, 217, 217, 1) 0%,
    rgba(230, 230, 230, 1) 35%,
    rgba(246, 246, 246, 1) 100%
  );
  width: 1100px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  margin-bottom: 50px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.btn-not {
  display: flex;
  justify-content: center;
  padding: 0 0 40px 0;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM PAGO MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.radio-box-group-style {
  margin-bottom: 30px;
}

.texto-contacto{
  float: right;
}

/*mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm BARRA DE NAVEGACION mmmmmmmmmmmmmmmmm*/
.container-nav {
  padding: 18px;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.container-nav ul {
  margin-right: 10%;
}
.container-nav li {
  list-style: none;
  display: inline-flex;
}

.container-nav li a {
  text-decoration: none;
  padding: 14px 20px;
  font-size: 16px;
  color: #f2f2f2;
  display: inline-block;
  text-align: center;
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  font-family: "Play", sans-serif;
}
.container-nav li a:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #f38115;
}

.barNavResp{
  display: none;
 
}

#upScrollBtn {
  position: fixed;
  z-index: 1000;
  right: 25px;
  bottom: 25px;
  width: 52px;
  height: 52px;
  color: orange;
  text-align: center;
  border-radius: 50%;
  transition: all 0.2s;
}
#upScrollBtn:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.hidden {
  opacity: 0;
  visibility: hidden;
}
.showIn {
  opacity: 1;
  visibility: visible;
}
#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 0;
  left: 0;
}
#progressBar {
  background: linear-gradient(
    to left,
    rgba(80, 0, 172, 0.616),
    rgb(255, 157, 234)
  );
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 0;
}
#progressBarContainer,
#progressBar {
  height: 5px;
}
/* Editor Noticia*/
.sun-editor .se-wrapper .se-wrapper-inner {
  min-height: 150px;
}
/* Sticky toast */
.div-toast {
  display: flex;
  width: 100vw;
  justify-content: flex-end;
  position: sticky !important;
}
.sticky-div {
  position: sticky !important;
  top: 0;
  right: 0;
}
/******** Sliders Noticias  **********/
[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 200px;
}

.number-slide {
  background: rgba(255, 255, 255, 0.8);
  background: linear-gradient(
    128deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

.item-slide {
  position: relative;
  height: 300px;
}
.slide-img {
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.item-slide .btn-slide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.navigation-wrapper{
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.institucional, .sociales{
  margin-top: 60px;
}





/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*RESPONSIVE*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

@media screen and (max-width: 760px) {
  .textos-hero {
    text-align: center;
    font-size: 30px;
  }

  /*MMMMMMMMMMMMM BARRA DE NAVEGACION SUPERIOR MMMMMMMMMMMM*/
  .container-nav > ul{
    display: none;
  }

  .barNavResp{
    display: block;
    top: 0;
    width: 100%;
    position: relative;
    
  }

 


 /*mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm*/
  .wave-contenedor {
    width: 100%;
  }

  .svg-hero {
    display: none;
  }

  .textos-hero h1 {
    font-size: 30px;
    text-align: center;
    color: #2c303b;
    margin-bottom: 50px;
  }

  .titulo.left {
    text-align: center;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .cursos {
    width: 100%;
  }

  .accordion {
    width: 80%;
    padding-left: 30px;
  }
  .nav-dip {
    width: 100%;
  }

  .info p {
    text-align: center;
  }

  .wave-contenedor > img {
    padding-top: 20px;
  }

  .cta1{
    width: 180px;
    height: 60px;
    font-size: .5em;
    
  }

  #contacto-id > div.boton-contacto > input{
    margin-left: 45px;
  }

  #inscribete-id > div.boton-notificacion > input{
    margin-left: 45px;
  }
  
   /*section*/
  .website {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .website img {
    width: 80%;
  }

  .website .contenedor-textos-main {
    width: 80%;
  }

  #contacto-id, #inscribete-id{
    display: flex;
    flex-direction: column;
  }

  .texto-contacto, .texto-notificacion{
    margin-left: 0;
  }


  .resp-ocult{
    display: none;
  }
  /*cards*/
  .card {
    width: 90%;
    margin-bottom: 20px;
  }

  .img-ofr > img {
    width: 90%;
  }

  .card1 > iframe {
    width: 70%;
  }

  iframe{
    width: 100%;
  }
  /*galeria*/
  .modal{
    width: 100%;
  }

  /*Catalogo*/
  .svg-wave{
    display: none;
  }

  .accordion .accordion-flush{
    margin-left: 20px;
    
  }

 .tabla-cursos{
    width: 20%;
    margin-left: -22px;
  }

  .pdfs{
    height: 50px;
    background: #dcdee0 ;
  }
 
  /*vitrina noticia*/

  .vitrina {
    background: #dcdee0;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
  }

  .caja-not {
    display: flex;
    flex-direction: column;
  }
  .img-not {
    max-width: 300px;
    max-height: 500px;
    padding: 30px 30px 30px 10px;
    margin-left:0;
  }

  body > div.fade.modal.show > div > div{
    width: 100%;
  }

  #example-custom-modal-styling-title{
    margin-left: 0px;
    font-size: 1.5em;
  }

  .title-not {
    padding-left: 0px;
    padding-top: 30px;
  }

  .title-not > h2 {
    text-align: center;
  }

  .body-not {
    padding-left: 30px;
  }

  .fmt-fecha {
    padding-left: 30px;
  }

  .contenedor > h2{
    padding-top: 50px;
  }

  .navigation-wrapper{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  p.parrafo.parrafo-i{
    margin-left: 20px;
    margin-bottom: 40px;
    text-align: left;
  }

  #contacto-id > div.texto-contacto > p{
    text-align: left;
    padding-top: 20px;
    padding-left: 20px;
    margin-right: 0;
  }

  #inscribete-id > div.texto-notificacion > p{
    text-align: left;
    padding-top: 20px;
    padding-left: 20px;
    margin-right: 0;
  }

  #root > div.social > div.social-flex > div.sociales{
    padding-left: 40px;
  }

  body > div.fade.modal.show > div > div > div.modal-body > div > article > p{
    text-align: left;
    padding-left: 10px;
    
  }
  
  #root > section.wave-contenedor.website > div > h2{
    padding-top: 30px;
    padding-bottom: 20px;
  }

  #example-custom-modal-styling-title > img{
    margin-right: 0;
    width: 50px;
    padding-right: 10px;
  }





  /*formulario de contacto*/
  .container-genp {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;

    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
  }

  .parrafo {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 20px;
    width: 85%;
  }

  .parrafo2{
    margin-bottom: 120px;
  }

  .reductor {
    width: 100%;
  }

  .container-1p,
  .container-2p,
  .container-3p {
    padding: 20px 20px 0 20px;
  }

  .container-1p {
    display: flex;
    margin-bottom: 0;
  }

  .container-2p {
    display: flex;
    flex-direction: column;
  }

  .container-3p {
    display: flex;
    justify-content: center;
  }

  .container-4p {
    display: flex;
    margin-top: 40px;
  }

  .b1 {
    width: 100%;
  }

  .cur-dip {
    display: flex;
    flex-direction: column;
    background: #dcdee0;
    width: 100%;
  }

  .lista-dip {
    padding-bottom: 50px;
  }

  #form-p {
    display: none;
  }

  .pago {
    width: 100%;
  }

  #contacto > label{
    padding-left: 0px;
  }

  .boton-notificacion{
    margin-top: 40px;
  }
  

  .container-genp {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    background-color: #683ec9;
    border-radius: 20px;
    margin: auto;
  }

  .container-1p,
  .container-2p,
  .container-3p {
    padding: 20px 20px 0 20px;
  }

  .container-1p {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  .container-2p {
    display: flex;
    flex-direction: column;
  }

  .container-3p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-4p {
    display: flex;
  }

  .inputp {
    background: transparent;
    border: 0;
    color: rgb(102, 96, 96);
    outline: none;
    border: 1px solid rgb(102, 96, 96);
    padding: 20px 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  select > option {
    color: white;
  }

  .nombrep {
    margin-right: 20px;
    width: 100%;
  }

  .emailp {
    width: 100%;
  }

  .nombrep::placeholder,
  .emailp::placeholder {
    color: white;
  }

  label {
    color: white;
    display: block;
  }

  .cursop {
    padding-bottom: 15px;
  }

  .diplomadop {
    padding: 15px 0 15px 0;
  }

  .titulop {
    color: white;
    text-align: center;
    padding-top: 20px;
  }

  .banco {
    width: 100%;
    padding-bottom: 20px;
  }

  .ref {
    width: 30%;
    align-self: center;
  }
  .monto {
    width: 100%;
    margin-top: 20px;
  }

  .ref::placeholder,
  .monto::placeholder {
    color: white;
  }

  .enviarp {
    width: 80px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  input[type="submit"]:hover {
    cursor: pointer;
    color: #683ec9;
    background: white;
  }

  .banc {
    text-align: center;
  }

  .cerrar {
    text-align: center;
    padding-top: 15px;
  }

  select > option {
    color: #1c0f92;
  }

  /*contacto*/

  .container-gen {
    display: flex;
    width: 100%;
  }

  .container-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input {
    width: 100%;
  }

  .container-2 {
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
  }

  .respon {
    display: flex;
  }

  .pais {
    width: 30%;
  }

  .tel {
    width: 50%;
  }

/**********************PAGO**************************/
    #contacto-id{
    margin-left: 0;
  }

  /*caja-Login*/

  .caja-login {
    width: 100%;
    height: 400px;
    margin-top: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    background: rgb(214, 212, 212);
    border: 1px solid rgb(102, 96, 96);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }

  .login-input {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .actz-cur {
    background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 1) 0%,
      rgba(230, 230, 230, 1) 35%,
      rgba(246, 246, 246, 1) 100%
    );
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 30px;
    border: 1px solid rgb(102, 96, 96);
  }

  .contenedorN {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    margin-bottom: 50px;
  }

  .social{
    margin-top: 80px;
    
  }

  .social-flex{
    background: #f38115;
    flex-direction: column;
  }

  .institucional{
    padding-top: 0px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-around;
  }

  .sociales{
    padding-right: 0px;
    margin-top: 30px;
    margin-left: 0;
  }

  .copyright{
    background: #f38115;
  }

  .direccion > p{
    margin-left: 30px;
    margin-top: 30px;
  }

  .bcv {
    padding-right: 0px;
  }

  #contacto > div > div > h1{
  margin-left: 10px;
  }


}

/*########################### Barra progreso  ###########################*/

